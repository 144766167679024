import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { FC, useEffect, useState } from 'react';
import { useApproveTaskMutation, useMarkLinkAsOpenedMutation, useResetActionMutation } from '../../../api/tenders';
import { Button, Modal, Tooltip } from 'antd';
import { Loader } from '../../Loader/Loader';
import { downloadFileFunc } from '../../../api/axiosCruds';
import SupplierListIcon from '../../../assets/SupplierListIcon.svg';
import attantion from '../../../assets/point.svg';
import Unverified from '../../../assets/Unverified.svg';
import Verified from '../../../assets/Verified.svg';
import messageGrey from '../../../assets/messageGrey.svg';
import stopImage from '../../../assets/stopImage.svg';
import igrey from '../../../assets/igrey.svg';
import plus from '../../../assets/Plus, Add.svg';
import ReceivedFile from '../../../assets/ReceivedFile.svg';
import Visit from '../../../assets/Visit.svg';
import downloadIcon from '../../../assets/Documents, File, Download.svg';
import NegotiatedSupplyAgreementIcon from '../../../assets/NegotiatedSupplyAgreementIcon.svg';
import done from '../../../assets/Done, Check.svg';
import editIcon from '../../../assets/editAdminIcon.svg';
import { RejectNegotiatedSupplyAgreement } from '../../Modals/RejectNegotiatedSupplyAgreement';
import { NegotiatedSupplyAgreement } from '../../Modals/NegotiatedSupplyAgreement';
import { ChangeRequestModal } from '../../Modals/ChangeRequestModal';
import { AgreementLinkModal } from '../../Modals/AgreementLinkModal';
import deleteIcon from '../../../assets/DeleteIcon.svg';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
}

export const AwardSupplyAgreement: FC<DefineSupportingDocumentTaskProps> = ({ data, role, messageApi, setTrigger }) => {
    const [isModal, setIsModal] = useState(false);
    const [taskId, setTaskId] = useState<number | null>(null);
    const [isDeleteFileModal, setIsDeleteFileModal] = useState<boolean>(false);
    const [isCancelChangeRequestModal, setIsCancelChangeRequestModal] = useState<boolean>(false);
    const [resetAction, { isLoading: isLoadingResetAction, isSuccess: isSuccessResetAction }] =
        useResetActionMutation();
    const [prevState, setPrevState] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [approveTask] = useApproveTaskMutation();
    const [isDeclineModal, setIsDeclineModal] = useState(false);
    const [markAsOpened, { isSuccess }] = useMarkLinkAsOpenedMutation();
    const [changeModal, setChangeModal] = useState(false);
    const [isResendModal, setIsResendModal] = useState(false);
    const [SupplierData, setSupplierData] = useState<any>(null);

    useEffect(() => {
        if (isSuccess) {
            setTrigger((prev) => !prev);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isSuccessResetAction) {
            setTrigger((prev) => !prev);
            setIsCancelChangeRequestModal(false);
        }
    }, [isSuccessResetAction]);

    const markLinkAsOpened = (id: number) => {
        if (!data?.id) return;
        const formData = {
            tender_id: data?.id,
            task_id: data?.phase_tasks?.find((elem) => elem.action === 'award_supply_agreement')?.id,
            data: { supplier_id: id }
        };
        markAsOpened(formData);
    };

    const deleteFunc = async () => {
        if (isLoadingResetAction) return;
        try {
            const formData: any = {
                tenderId: data.id,
                taskId: taskId,
                data: { action: 'decline' }
            };

            await resetAction(formData).unwrap();
        } catch {}
    };

    const downloadFunc = (fileId: string, taskId: number) => {
        setLoading(true);
        downloadFileFunc({
            tenderId: data.id,
            taskId: taskId,
            fileId: fileId
        })
            .then((response: any) => {
                const stplitArray = response.headers['content-disposition'].split(';');
                const element = stplitArray.find((elem: string) => elem.includes('filename'));
                const name = element.split('=')[1];

                downloadPDF(response, name);
                setLoading(false);
            })
            .catch((err) => {
                messageApi.error('Downloading Error. Check your internet connection');
            });
    };

    const downloadPDF = (response: any, filename: any) => {
        let blob = new Blob([response.data], {
            type: response.headers['content-type']
        });

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = filename.replace(/"/g, '').trim();
        link.download = fileName;
        link.target = '_blank';

        const el = document.body;
        el.appendChild(link);

        link.click();
        setTrigger((prev: boolean) => !prev);
        messageApi.success('File has been downloaded successfully');
    };

    const approveTaskFunc = async (taskId: number) => {
        try {
            const formData = {
                tenderId: data?.id,
                taskId: taskId
            };
            await approveTask(formData).unwrap();
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const statusImage = (status: string) => {
        if (status === 'Unverified') {
            return Unverified;
        }
        if (status === 'Verified') {
            return Verified;
        }
    };

    return (
        <>
            {data?.phase_tasks.length ? (
                data?.phase_tasks?.map((elem: taskProps) => {
                    if (elem.action === 'award_supply_agreement') {
                        if (role !== 'school_user') {
                            if (
                                elem.status === 'pending_tr' &&
                                !elem.approved &&
                                !elem.declined &&
                                !elem.links?.suppliers?.[0]?.link
                            ) {
                                return (
                                    <div key={3} className={`tasks__task task `}>
                                        <div className="task__title">
                                            <div className="task-complete__title-row">
                                                <img src={NegotiatedSupplyAgreementIcon} />
                                                {elem.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        {role !== 'super_admin' && (
                                            <img
                                                src={plus}
                                                className="task__plus"
                                                onClick={() => {
                                                    setTaskId(elem.id);
                                                    setIsModal(true);
                                                }}
                                            />
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_tr' && elem.links?.suppliers?.[0]?.link) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem.name}
                                            </div>
                                            <img
                                                src={editIcon}
                                                className="task__plus"
                                                onClick={() => {
                                                    setIsModal(true);
                                                    setTaskId(elem.id);
                                                }}
                                            />
                                        </div>
                                        {data.suppliers?.map((item) => {
                                            return (
                                                <>
                                                    <div className="task-complete__supplier supplier ">
                                                        <div className="supplier__header with-switcher">
                                                            <div className="supplier__wrapper">
                                                                <img src={statusImage(item.company_status_name)} />{' '}
                                                                {item.company_status_name}
                                                                {item.status === 'shortlisted' && (
                                                                    <div
                                                                        className="task-complete__title-row"
                                                                        style={{ margin: '0 0 0 20px' }}
                                                                    >
                                                                        <div className="task-complete__approved-active">
                                                                            <img src={done} />
                                                                            Awarded by School User
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {item.incumbent && (
                                                                <div className="supplier__incumbent">
                                                                    <img src={attantion} />
                                                                    Incumbent Supplier
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="supplier__row">
                                                            <div className="supplier__wrap first">
                                                                <div className="supplier__title">
                                                                    ({item.name_prefix}) {item.name}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {item.trading_name}
                                                                </div>
                                                            </div>
                                                            {item.manager_name ? (
                                                                <div className="supplier__wrap">
                                                                    <div className="supplier__title">
                                                                        {item.manager_name ? item.manager_name : ''}
                                                                    </div>
                                                                    <div className="supplier__subtitle">
                                                                        {item?.email ? item?.email : ''}{' '}
                                                                        {item?.phone_number ? item?.phone_number : ''}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="supplier__wrap"></div>
                                                            )}
                                                        </div>
                                                        {elem.links?.suppliers?.find((link) => link.id === item.id)
                                                            ?.link ? (
                                                            <div className="supplier-SU__supplier_aggrement_wrapper">
                                                                <a
                                                                    href={
                                                                        elem.links?.suppliers?.find(
                                                                            (link) => link.id === item.id
                                                                        )?.link
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <div className="supplier-SU__white-btn">
                                                                        Supplier Agreement <img src={Visit} />
                                                                    </div>
                                                                </a>
                                                                {elem.status !== 'pending_tr' && (
                                                                    <img
                                                                        src={editIcon}
                                                                        className="supplier__delete"
                                                                        style={{ margin: '0 0 0 10px' }}
                                                                        // onClick={() => {
                                                                        //     setTaskId(elem.id);
                                                                        //     setSupplierData(item);
                                                                        //     setIsAgreementLinkModal(true);
                                                                        // }}
                                                                    />
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </>
                                            );
                                        })}
                                        {elem.comments.tender_representative && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Comment</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        {elem.comments.tender_representative}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_su' && elem.links?.sent_suppliers?.[0]?.link) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem.name}
                                            </div>

                                            <div
                                                className="supplier-SU__white-btn"
                                                onClick={() => {
                                                    setTaskId(elem.id);
                                                    setIsModal(true);
                                                }}
                                            >
                                                Resend Links{' '}
                                            </div>
                                        </div>
                                        {data.suppliers?.map((item) => {
                                            return (
                                                <>
                                                    <div className="task-complete__supplier supplier ">
                                                        <div className="supplier__header with-switcher">
                                                            <div className="supplier__wrapper">
                                                                <img src={statusImage(item.company_status_name)} />{' '}
                                                                {item.company_status_name}
                                                                {item.status === 'shortlisted' && (
                                                                    <div
                                                                        className="task-complete__title-row"
                                                                        style={{ margin: '0 0 0 20px' }}
                                                                    >
                                                                        <div className="task-complete__approved-active">
                                                                            <img src={done} />
                                                                            Awarded by School User
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {item.incumbent && (
                                                                <div className="supplier__incumbent">
                                                                    <img src={attantion} />
                                                                    Incumbent Supplier
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="supplier__row">
                                                            <div className="supplier__wrap first">
                                                                <div className="supplier__title">
                                                                    ({item.name_prefix}) {item.name}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {item.trading_name}
                                                                </div>
                                                            </div>
                                                            {item.manager_name ? (
                                                                <div className="supplier__wrap">
                                                                    <div className="supplier__title">
                                                                        {item.manager_name ? item.manager_name : ''}
                                                                    </div>
                                                                    <div className="supplier__subtitle">
                                                                        {item?.email ? item?.email : ''}{' '}
                                                                        {item?.phone_number ? item?.phone_number : ''}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="supplier__wrap"></div>
                                                            )}
                                                        </div>
                                                        {elem.links?.sent_suppliers?.find((link) => link.id === item.id)
                                                            ?.link ? (
                                                            <div className="supplier-SU__negotiated_aggrement_wrapper">
                                                                <a
                                                                    href={
                                                                        elem.links?.sent_suppliers?.find(
                                                                            (link) => link.id === item.id
                                                                        )?.link
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <div className="supplier-SU__white-btn">
                                                                        Supplier Agreement <img src={Visit} />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </>
                                            );
                                        })}
                                        {elem.comments?.tender_representative_sent && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Comment</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        {elem.comments.tender_representative_sent}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={NegotiatedSupplyAgreementIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                {role !== 'super_admin' && (
                                                    <Tooltip
                                                        title="To resend file, you need to download file first"
                                                        overlayClassName={
                                                            elem.file_downloaded
                                                                ? 'custom-tooltip'
                                                                : 'custom-tooltip-disabled'
                                                        }
                                                    >
                                                        <div
                                                            className={`task-complete__decline ${
                                                                elem.file_downloaded ? '' : 'disable'
                                                            }`}
                                                            onClick={() => {
                                                                if (elem.file_downloaded) {
                                                                    setTaskId(elem.id);
                                                                    setIsModal(true);
                                                                }
                                                            }}
                                                        >
                                                            Resend
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>
                                        <>
                                            {elem.files.sent_su?.[0].name && (
                                                <div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">Received File</div>
                                                    </div>{' '}
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className="task-complete__value file">
                                                            <img src={ReceivedFile} className="upload-download-icon" />
                                                            {elem.files.sent_su[0].name}
                                                        </div>
                                                        <div
                                                            className={`task-complete__download-btn `}
                                                            onClick={() => {
                                                                downloadFunc(elem.files.sent_su[0].uuid, elem.id);
                                                            }}
                                                        >
                                                            <img src={downloadIcon} className="upload-download-icon" />
                                                            Download
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__value school-user">
                                                    <img src={messageGrey} />
                                                    {elem.comments.school_user}
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                );
                            }
                            if (elem.status === 'completed' && elem.approved) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem.name}
                                            </div>
                                            <span className="task__approved-active " style={{ width: '235px' }}>
                                                <img src={done} />
                                                Approved by School User
                                            </span>
                                        </div>
                                        {data.suppliers?.map((item) => {
                                            return (
                                                <>
                                                    <div className="task-complete__supplier supplier ">
                                                        <div className="supplier__header with-switcher">
                                                            <div className="supplier__wrapper">
                                                                <img src={statusImage(item.company_status_name)} />{' '}
                                                                {item.company_status_name}
                                                                {item.status === 'shortlisted' && (
                                                                    <div
                                                                        className="task-complete__title-row"
                                                                        style={{ margin: '0 0 0 20px' }}
                                                                    >
                                                                        <div className="task-complete__approved-active">
                                                                            <img src={done} />
                                                                            Awarded by School User
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {item.incumbent && (
                                                                <div className="supplier__incumbent">
                                                                    <img src={attantion} />
                                                                    Incumbent Supplier
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="supplier__row">
                                                            <div className="supplier__wrap first">
                                                                <div className="supplier__title">
                                                                    ({item.name_prefix}) {item.name}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {item.trading_name}
                                                                </div>
                                                            </div>
                                                            {item.manager_name ? (
                                                                <div className="supplier__wrap">
                                                                    <div className="supplier__title">
                                                                        {item.manager_name ? item.manager_name : ''}
                                                                    </div>
                                                                    <div className="supplier__subtitle">
                                                                        {item?.email ? item?.email : ''}{' '}
                                                                        {item?.phone_number ? item?.phone_number : ''}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="supplier__wrap"></div>
                                                            )}
                                                        </div>
                                                        {elem.links?.sent_suppliers?.find((link) => link.id === item.id)
                                                            ?.link ? (
                                                            <div className="supplier-SU__negotiated_aggrement_wrapper">
                                                                <a
                                                                    href={
                                                                        elem.links?.sent_suppliers?.find(
                                                                            (link) => link.id === item.id
                                                                        )?.link
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <div className="supplier-SU__white-btn">
                                                                        Supplier Agreement <img src={Visit} />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </>
                                            );
                                        })}
                                        {elem.comments.tender_representative_sent && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Comment</div>
                                                </div>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value">
                                                        {elem.comments.tender_representative_sent}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'completed' && elem.declined) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete `}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={SupplierListIcon} />
                                                {elem.name}
                                            </div>
                                            <div className="task-complete__title-row yellow">Change Requested</div>
                                        </div>
                                        {data.suppliers?.map((item) => {
                                            return (
                                                <>
                                                    <div className="task-complete__supplier supplier ">
                                                        <div className="supplier__header with-switcher">
                                                            <div className="supplier__wrapper">
                                                                <img src={statusImage(item.company_status_name)} />{' '}
                                                                {item.company_status_name}
                                                                {item.status === 'shortlisted' && (
                                                                    <div
                                                                        className="task-complete__title-row"
                                                                        style={{ margin: '0 0 0 20px' }}
                                                                    >
                                                                        <div className="task-complete__approved-active">
                                                                            <img src={done} />
                                                                            Awarded by School User
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {item.incumbent && (
                                                                <div className="supplier__incumbent">
                                                                    <img src={attantion} />
                                                                    Incumbent Supplier
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="supplier__row">
                                                            <div className="supplier__wrap first">
                                                                <div className="supplier__title">
                                                                    ({item.name_prefix}) {item.name}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {item.trading_name}
                                                                </div>
                                                            </div>
                                                            {item.manager_name ? (
                                                                <div className="supplier__wrap">
                                                                    <div className="supplier__title">
                                                                        {item.manager_name ? item.manager_name : ''}
                                                                    </div>
                                                                    <div className="supplier__subtitle">
                                                                        {item?.email ? item?.email : ''}{' '}
                                                                        {item?.phone_number ? item?.phone_number : ''}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="supplier__wrap"></div>
                                                            )}
                                                        </div>
                                                        {elem.links?.sent_suppliers?.find((link) => link.id === item.id)
                                                            ?.link ? (
                                                            <div className="supplier-SU__negotiated_aggrement_wrapper">
                                                                <a
                                                                    href={
                                                                        elem.links?.sent_suppliers?.find(
                                                                            (link) => link.id === item.id
                                                                        )?.link
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <div className="supplier-SU__white-btn">
                                                                        Supplier Agreement <img src={Visit} />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </>
                                            );
                                        })}

                                        {elem.comments.school_user && (
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__value school-user">
                                                        <img src={messageGrey} />
                                                        {elem.comments.school_user}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                        }
                        if (role === 'school_user') {
                            if (elem.status === 'pending_su' && !elem.approved && !elem.declined) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={NegotiatedSupplyAgreementIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <Tooltip
                                                    title="To Request Changes or Approve documents, you need open Negotiated Supply Agreement first!"
                                                    overlayClassName={` ${
                                                        !elem.links?.sent_suppliers?.every((link) => link.is_clicked)
                                                            ? 'custom-tooltip-disabled'
                                                            : 'custom-tooltip'
                                                    }`}
                                                >
                                                    <div
                                                        className={`task-complete__decline ${
                                                            elem.links?.sent_suppliers?.every((link) => link.is_clicked)
                                                                ? ''
                                                                : 'disable'
                                                        }`}
                                                        onClick={() => {
                                                            if (
                                                                elem.links?.sent_suppliers?.every(
                                                                    (link) => link.is_clicked
                                                                )
                                                            ) {
                                                                setTaskId(elem.id);
                                                                setChangeModal(true);
                                                            }
                                                        }}
                                                    >
                                                        Request Changes
                                                    </div>
                                                </Tooltip>
                                                <Tooltip
                                                    title="To Request Changes or Approve documents, you need open Negotiated Supply Agreement first!"
                                                    overlayClassName={` ${
                                                        !elem.links?.sent_suppliers?.every((link) => link.is_clicked)
                                                            ? 'custom-tooltip-disabled'
                                                            : 'custom-tooltip'
                                                    }`}
                                                >
                                                    <div
                                                        className={`task-complete__approved ${
                                                            !elem.links?.sent_suppliers?.every(
                                                                (link) => link.is_clicked
                                                            )
                                                                ? 'disabled-blue-button'
                                                                : ''
                                                        }`}
                                                        onClick={() => {
                                                            if (
                                                                elem.links?.sent_suppliers?.every(
                                                                    (link) => link.is_clicked
                                                                )
                                                            ) {
                                                                approveTaskFunc(elem.id);
                                                            }
                                                        }}
                                                    >
                                                        Approve
                                                    </div>{' '}
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Received Files</div>
                                            </div>
                                            {elem.links.sent_suppliers?.map((item: any) => {
                                                return (
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value links">
                                                            <div>
                                                                <div className="task-complete__value_name">
                                                                    {
                                                                        data?.suppliers.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.name
                                                                    }
                                                                </div>
                                                                <div className="task-complete__value__tr_name">
                                                                    {
                                                                        data?.suppliers.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.trading_name
                                                                    }
                                                                </div>
                                                            </div>

                                                            <a
                                                                href={item?.link}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                onClick={() => markLinkAsOpened(item.id)}
                                                            >
                                                                <div className={`task-complete__link-btn `}>
                                                                    Negotiated Supply Agreement{' '}
                                                                    <img src={Visit} className="upload-download-icon" />
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {elem.comments.tender_representative_sent && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments.tender_representative_sent}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_su' && elem.approved) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={NegotiatedSupplyAgreementIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <div
                                                    className={`task-complete__decline ${
                                                        elem.links?.sent_suppliers?.every((link) => link.is_clicked)
                                                            ? ''
                                                            : 'disable'
                                                    }`}
                                                    onClick={() => {
                                                        if (
                                                            elem.links?.sent_suppliers?.every((link) => link.is_clicked)
                                                        ) {
                                                            setTaskId(elem.id);
                                                            setChangeModal(true);
                                                        }
                                                    }}
                                                >
                                                    Request Changes
                                                </div>

                                                <span className="task__approved-active">
                                                    <img src={done} />
                                                    Approved
                                                </span>
                                            </div>
                                        </div>
                                        <>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Received Files</div>
                                            </div>
                                            {elem.links.sent_suppliers?.map((item: any) => {
                                                return (
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value links">
                                                            <div>
                                                                <div className="task-complete__value_name">
                                                                    {
                                                                        data?.suppliers.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.name
                                                                    }
                                                                </div>
                                                                <div className="task-complete__value__tr_name">
                                                                    {
                                                                        data?.suppliers.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.trading_name
                                                                    }
                                                                </div>
                                                            </div>

                                                            <a
                                                                href={item?.link}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                onClick={() => markLinkAsOpened(item.id)}
                                                            >
                                                                <div className={`task-complete__link-btn `}>
                                                                    Negotiated Supply Agreement{' '}
                                                                    <img src={Visit} className="upload-download-icon" />
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {elem.comments.tender_representative_sent && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments.tender_representative_sent}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    </div>
                                );
                            }
                            if (elem.status === 'pending_su' && elem.declined) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete yellow`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={NegotiatedSupplyAgreementIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row yellow">Change Requested</div>
                                        </div>
                                        <>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Received Files</div>
                                            </div>
                                            {elem.links.sent_suppliers?.map((item: any) => {
                                                return (
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value links">
                                                            <div>
                                                                <div className="task-complete__value_name">
                                                                    {
                                                                        data?.suppliers.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.name
                                                                    }
                                                                </div>
                                                                <div className="task-complete__value__tr_name">
                                                                    {
                                                                        data?.suppliers.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.trading_name
                                                                    }
                                                                </div>
                                                            </div>

                                                            <a
                                                                href={item?.link}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                onClick={() => markLinkAsOpened(item.id)}
                                                            >
                                                                <div className={`task-complete__link-btn `}>
                                                                    Negotiated Supply Agreement{' '}
                                                                    <img src={Visit} className="upload-download-icon" />
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {elem.comments.tender_representative_sent && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments.tender_representative_sent}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                        {elem.comments.school_user && (
                                            <div className="task-complete__info-wrap">
                                                <div className="task-complete__wrap column">
                                                    <div className="task-complete__name">My Comment</div>
                                                    <div className="task-complete__value">
                                                        {elem.comments.school_user}
                                                    </div>
                                                </div>
                                                <img
                                                    src={deleteIcon}
                                                    className="task-complete__edit"
                                                    onClick={() => {
                                                        setTaskId(elem.id);
                                                        setIsCancelChangeRequestModal(true);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                            if (elem.status === 'need_resend_tr') {
                                return (
                                    <>
                                        {' '}
                                        <div key={elem.id} className={`tasks__task-complete task-complete red`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={NegotiatedSupplyAgreementIcon} />
                                                    {elem?.name}
                                                    <Tooltip
                                                        title={
                                                            <div style={{ fontWeight: '400' }}>
                                                                Negotiated Supply Agreement: Here, you can view the
                                                                changes proposed by your appointed supplier to the
                                                                previously approved Supply Agreement. <br />
                                                                <span style={{ fontWeight: '600' }}>Instruction</span>:
                                                                Review the proposed changes. If acceptable, approve
                                                                them. If updates are needed, inform us about the
                                                                required revisions.
                                                            </div>
                                                        }
                                                    >
                                                        <img src={igrey} className="task-complete__igrey" />
                                                    </Tooltip>
                                                </div>
                                                <div className="task-complete__title-row">
                                                    <div className="task-complete__decline-active">
                                                        <img src={stopImage} />
                                                        Rejected
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Received Files</div>
                                            </div>
                                            {elem.files.sent_suppliers?.map((item: any) => {
                                                return (
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img
                                                                    src={ReceivedFile}
                                                                    className="upload-download-icon"
                                                                />
                                                                {item.name}

                                                                <div
                                                                    className={`task-complete__download-btn `}
                                                                    onClick={() => {
                                                                        downloadFunc(item.file_uuid, elem.id);
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={downloadIcon}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    Download
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {elem.comments.tender_representative_sent && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments.tender_representative_sent}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div className="task-complete__info-wrap">
                                                <>
                                                    {elem.files.sent_su?.[0].name && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__name">Uploaded File</div>
                                                            </div>

                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value">
                                                                    <div>
                                                                        <img
                                                                            src={ReceivedFile}
                                                                            className="upload-download-icon"
                                                                        />
                                                                        {elem.files.sent_su[0].name}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">My Comment</div>
                                                    </div>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value">
                                                            {elem.comments.school_user}
                                                        </div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                            if (elem.status === 'completed' && elem.approved) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={NegotiatedSupplyAgreementIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row">
                                                <span className="task__approved-active">
                                                    <img src={done} />
                                                    Approved
                                                </span>
                                            </div>
                                        </div>
                                        <>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Received Files</div>
                                            </div>
                                            {elem.links.sent_suppliers?.map((item: any) => {
                                                return (
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value links">
                                                            <div>
                                                                <div className="task-complete__value_name">
                                                                    {
                                                                        data?.suppliers.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.name
                                                                    }
                                                                </div>
                                                                <div className="task-complete__value__tr_name">
                                                                    {
                                                                        data?.suppliers.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.trading_name
                                                                    }
                                                                </div>
                                                            </div>

                                                            <a href={item?.link} target="_blank" rel="noreferrer">
                                                                <div className={`task-complete__link-btn `}>
                                                                    Negotiated Supply Agreement{' '}
                                                                    <img src={Visit} className="upload-download-icon" />
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {elem.comments.tender_representative_sent && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments.tender_representative_sent}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    </div>
                                );
                            }
                            if (elem.status === 'completed' && elem.declined) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete yellow`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={NegotiatedSupplyAgreementIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row yellow">Change Requested</div>
                                        </div>
                                        <>
                                            <div className="task-complete__wrap">
                                                <div className="task-complete__name">Received Files</div>
                                            </div>
                                            {elem.links.sent_suppliers?.map((item: any) => {
                                                return (
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value links">
                                                            <div>
                                                                <div className="task-complete__value_name">
                                                                    {
                                                                        data?.suppliers.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.name
                                                                    }
                                                                </div>
                                                                <div className="task-complete__value__tr_name">
                                                                    {
                                                                        data?.suppliers.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.trading_name
                                                                    }
                                                                </div>
                                                            </div>

                                                            <a href={item?.link} target="_blank" rel="noreferrer">
                                                                <div className={`task-complete__link-btn `}>
                                                                    Negotiated Supply Agreement{' '}
                                                                    <img src={Visit} className="upload-download-icon" />
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {elem.comments.tender_representative_sent && (
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__value school-user">
                                                            <img src={messageGrey} />
                                                            {elem.comments.tender_representative_sent}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {elem.comments.school_user && (
                                                <div className="task-complete__info-wrap">
                                                    <div className="task-complete__wrap column">
                                                        <div className="task-complete__name">My Comment</div>
                                                        <div className="task-complete__value">
                                                            {elem.comments.school_user}
                                                        </div>
                                                    </div>
                                                    <img
                                                        src={deleteIcon}
                                                        className="task-complete__edit"
                                                        onClick={() => {
                                                            setTaskId(elem.id);
                                                            setIsCancelChangeRequestModal(true);
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    </div>
                                );
                            }
                        }
                    }
                })
            ) : (
                <></>
            )}

            {isModal && (
                <NegotiatedSupplyAgreement
                    openModal={isModal}
                    setOpenModal={setIsModal}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    isResend={
                        data?.phase_tasks?.find((elem) => elem.action === 'award_supply_agreement')?.status ===
                        'pending_su'
                    }
                />
            )}
            {changeModal && (
                <ChangeRequestModal
                    openModal={changeModal}
                    setOpenModal={setChangeModal}
                    setTrigger={setTrigger}
                    data={data}
                />
            )}
            {isDeclineModal && (
                <RejectNegotiatedSupplyAgreement
                    openModal={isDeclineModal}
                    setOpenModal={setIsDeclineModal}
                    setTrigger={setTrigger}
                    data={data}
                    taskId={taskId}
                />
            )}
            {isResendModal && (
                <AgreementLinkModal
                    openModal={isResendModal}
                    setOpenModal={setIsResendModal}
                    setTrigger={setTrigger}
                    SupplierData={SupplierData}
                    data={data}
                    isResend={true}
                />
            )}
            {isDeleteFileModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteFileModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteFileModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button className="quit" style={{ width: '100%' }} onClick={deleteFunc}>
                                {isLoadingResetAction ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to delete the file?
                        </div>
                    </div>
                </Modal>
            )}
            {isCancelChangeRequestModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isCancelChangeRequestModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button
                                key="back"
                                onClick={() => setIsCancelChangeRequestModal(false)}
                                block
                                className="cancel"
                            >
                                Cancel
                            </Button>
                            <button className="quit" style={{ width: '100%' }} onClick={deleteFunc}>
                                {isLoadingResetAction ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want delete change request?
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
