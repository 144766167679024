import { taskProps, tenderDataProps } from '../../../pages/TenderViewPage/types';
import { FC, SetStateAction, useState } from 'react';
import { useApproveTaskMutation, useResetActionMutation } from '../../../api/tenders';
import { Button, Modal, Tooltip } from 'antd';
import { Loader } from '../../Loader/Loader';
import { downloadFileFunc } from '../../../api/axiosCruds';
import Unverified from '../../../assets/Unverified.svg';
import Verified from '../../../assets/Verified.svg';
import messageGrey from '../../../assets/messageGrey.svg';
import Confirmed from '../../../assets/Confirmed.svg';
import Unconfirmed from '../../../assets/Unconfirmed.svg';
import calendar from '../../../assets/Calendar, Schedule, Clock.svg';
import Visit from '../../../assets/Visit.svg';
import attantion from '../../../assets/point.svg';
import editIcon from '../../../assets/editAdminIcon.svg';

import stopImage from '../../../assets/stopImage.svg';
import edit from '../../../assets/editAdminIcon.svg';
import plus from '../../../assets/Plus, Add.svg';
import ReceivedFile from '../../../assets/ReceivedFile.svg';
import pendingIcon from '../../../assets/panding-circle.svg';
import deleteIcon from '../../../assets/DeleteIcon.svg';
import downloadIcon from '../../../assets/Documents, File, Download.svg';
import СompanyВocumentationIcon from '../../../assets/СompanyВocumentationIcon.svg';
import done from '../../../assets/Done, Check.svg';

import { SuppliersFilesModal } from '../../Modals/SuppliersFilesModal';
import { RejectNegotiatedSupplyAgreement } from '../../Modals/RejectNegotiatedSupplyAgreement';
import { UploadCompanyDocumentationModal } from '../../Modals/UploadCompanyDocumentationModal';
import { downloadSingleFileFunc } from '../../../api/axiosCruds';

interface DefineSupportingDocumentTaskProps {
    data: tenderDataProps;
    role: string | null;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    messageApi: any;
}

export const CompanyDocumentation: FC<DefineSupportingDocumentTaskProps> = ({ data, role, messageApi, setTrigger }) => {
    const [isModal, setIsModal] = useState(false);
    const [taskId, setTaskId] = useState<number | null>(null);
    const [isDeleteFileModal, setIsDeleteFileModal] = useState<boolean>(false);
    const [resetAction, { isLoading: isLoadingResetAction }] = useResetActionMutation();
    const [prevState, setPrevState] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [approveTask] = useApproveTaskMutation();
    const [isDeclineModal, setIsDeclineModal] = useState(false);
    const [isUploadDocumentsModal, setIsUploadDocumentsModal] = useState(false);
    const [supplierData, setSupplierData] = useState({});

    const openFile = (uuid: string) => {
        downloadSingleFileFunc(uuid)
            .then((response: any) => {
                const blob = new Blob([response.data], {
                    type: response.headers['content-type']
                });
                const fileURL = URL.createObjectURL(blob);

                window.open(fileURL, '_blank');
            })
            .catch(() => {
                messageApi.error('Opening file Error. Check your internet connection');
            });
    };

    const deleteFileFunc = async () => {
        try {
            const formData: any = {
                tenderId: data.id,
                taskId: taskId,
                data: { action: 'decline' }
            };

            await resetAction(formData).unwrap();

            await setIsDeleteFileModal(false);
            await setTrigger((prev: boolean) => !prev);
        } catch {}
    };

    const downloadFunc = (fileId: string, taskId: number) => {
        setLoading(true);
        downloadFileFunc({
            tenderId: data.id,
            taskId: taskId,
            fileId: fileId
        })
            .then((response: any) => {
                const stplitArray = response.headers['content-disposition'].split(';');
                const element = stplitArray.find((elem: string) => elem.includes('filename'));
                const name = element.split('=')[1];

                downloadPDF(response, name);
                setLoading(false);
            })
            .catch((err) => {
                messageApi.error('Downloading Error. Check your internet connection');
            });
    };

    const downloadPDF = (response: any, filename: any) => {
        let blob = new Blob([response.data], {
            type: response.headers['content-type']
        });

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = filename.replace(/"/g, '').trim();
        link.download = fileName;
        link.target = '_blank';

        const el = document.body;
        el.appendChild(link);

        link.click();
        setTrigger((prev: boolean) => !prev);
        messageApi.success('File has been downloaded successfully');
    };

    const statusImage = (status: string) => {
        if (status === 'Unverified') {
            return Unverified;
        }
        if (status === 'Verified') {
            return Verified;
        }
    };

    return (
        <>
            {data?.phase_tasks.length ? (
                data?.phase_tasks.map((elem: taskProps) => {
                    if (elem.action === 'award_company_documentation') {
                        if (role !== 'school_user') {
                            if (elem.status === 'pending_tr' && !elem.approved && !elem.declined) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete ${
                                            elem.files.suppliers.every((supp: any) => supp.documents.length === 4)
                                                ? 'green'
                                                : ''
                                        }
                              `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={СompanyВocumentationIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>

                                        {elem.files.suppliers.map((item: any) => {
                                            return (
                                                <div className="task-complete__supplier supplier ">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img
                                                                src={statusImage(
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.company_status_name || ''
                                                                )}
                                                            />{' '}
                                                            {
                                                                data?.suppliers?.find((supp) => supp.id === item.id)
                                                                    ?.company_status_name
                                                            }
                                                            {data?.suppliers?.find((supp) => supp.id === item.id)
                                                                ?.status === 'shortlisted' && (
                                                                <div
                                                                    className="task-complete__title-row"
                                                                    style={{ margin: '0 0 0 15px' }}
                                                                >
                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Awarded
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {data?.suppliers?.find((supp) => supp.id === item.id)
                                                            ?.incumbent && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__row" style={{ position: 'relative' }}>
                                                        <div className="supplier__wrap first with-documents">
                                                            <div className="supplier__title">
                                                                (
                                                                {
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.name_prefix
                                                                }
                                                                ){' '}
                                                                {
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.name
                                                                }
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.trading_name
                                                                }
                                                            </div>
                                                        </div>
                                                        {data?.suppliers?.find((supp) => supp.id === item.id)
                                                            ?.manager_name ? (
                                                            <div className="supplier__wrap with-documents">
                                                                <div className="supplier__title">
                                                                    {data?.suppliers?.find(
                                                                        (supp) => supp.id === item.id
                                                                    )?.manager_name
                                                                        ? data?.suppliers?.find(
                                                                              (supp) => supp.id === item.id
                                                                          )?.manager_name
                                                                        : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {data?.suppliers?.find(
                                                                        (supp) => supp.id === item.id
                                                                    )?.email
                                                                        ? data?.suppliers?.find(
                                                                              (supp) => supp.id === item.id
                                                                          )?.email
                                                                        : ''}{' '}
                                                                    {data?.suppliers?.find(
                                                                        (supp) => supp.id === item.id
                                                                    )?.phone_number
                                                                        ? data?.suppliers?.find(
                                                                              (supp) => supp.id === item.id
                                                                          )?.phone_number
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}
                                                        {!item.has_documents && !!item.documents.length && (
                                                            <img
                                                                src={editIcon}
                                                                className="supplier__edit-documents"
                                                                onClick={() => {
                                                                    setTaskId(elem.id);
                                                                    setIsUploadDocumentsModal(true);
                                                                    setSupplierData(item);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    {item.documents.length ? (
                                                        <>
                                                            <div className="supplier__documents">
                                                                <div className="supplier__documents-row">
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                Financial/Management Accounts
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[0]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[0]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                SARS Letter of Good Standing
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[1]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[1]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="supplier__documents-row">
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                B-BBEE Certificate/Affidavit
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[2]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[2]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                Company Registration Certificate
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[3]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[3]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {item?.comment && (
                                                                <div
                                                                    className="task-complete__info-wrap"
                                                                    style={{ width: '96%', margin: '12px' }}
                                                                >
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            Comment
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__value">
                                                                            {item.comment}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div
                                                            className="supplier-SU__white-btn slots"
                                                            onClick={() => {
                                                                setTaskId(elem.id);
                                                                setIsUploadDocumentsModal(true);
                                                                setSupplierData(item);
                                                            }}
                                                        >
                                                            <img src={calendar} /> Upload documents
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }

                            if (
                                elem.status === 'pending_su' &&
                                !elem.approved &&
                                !elem.declined &&
                                elem.files.sent_suppliers.length
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete
                              `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={СompanyВocumentationIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task__pending">
                                                <img src={pendingIcon} />
                                                Pending
                                            </div>
                                        </div>

                                        {elem.files.suppliers.map((item: any) => {
                                            return (
                                                <div className="task-complete__supplier supplier ">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img
                                                                src={statusImage(
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.company_status_name || ''
                                                                )}
                                                            />{' '}
                                                            {
                                                                data?.suppliers?.find((supp) => supp.id === item.id)
                                                                    ?.company_status_name
                                                            }
                                                            {data?.suppliers?.find((supp) => supp.id === item.id)
                                                                ?.status === 'shortlisted' && (
                                                                <div
                                                                    className="task-complete__title-row"
                                                                    style={{ margin: '0 0 0 15px' }}
                                                                >
                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Awarded
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {data?.suppliers?.find((supp) => supp.id === item.id)
                                                            ?.incumbent && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__row" style={{ position: 'relative' }}>
                                                        <div className="supplier__wrap first with-documents">
                                                            <div className="supplier__title">
                                                                (
                                                                {
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.name_prefix
                                                                }
                                                                ){' '}
                                                                {
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.name
                                                                }
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.trading_name
                                                                }
                                                            </div>
                                                        </div>
                                                        {data?.suppliers?.find((supp) => supp.id === item.id)
                                                            ?.manager_name ? (
                                                            <div className="supplier__wrap with-documents">
                                                                <div className="supplier__title">
                                                                    {data?.suppliers?.find(
                                                                        (supp) => supp.id === item.id
                                                                    )?.manager_name
                                                                        ? data?.suppliers?.find(
                                                                              (supp) => supp.id === item.id
                                                                          )?.manager_name
                                                                        : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {data?.suppliers?.find(
                                                                        (supp) => supp.id === item.id
                                                                    )?.email
                                                                        ? data?.suppliers?.find(
                                                                              (supp) => supp.id === item.id
                                                                          )?.email
                                                                        : ''}{' '}
                                                                    {data?.suppliers?.find(
                                                                        (supp) => supp.id === item.id
                                                                    )?.phone_number
                                                                        ? data?.suppliers?.find(
                                                                              (supp) => supp.id === item.id
                                                                          )?.phone_number
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}
                                                    </div>
                                                    {item.documents.length ? (
                                                        <>
                                                            <div className="supplier__documents">
                                                                <div className="supplier__documents-row">
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                Financial/Management Accounts
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[0]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[0]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                SARS Letter of Good Standing
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[1]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[1]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="supplier__documents-row">
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                B-BBEE Certificate/Affidavit
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[2]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[2]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                Company Registration Certificate
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[3]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[3]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {item?.comment && (
                                                                <div
                                                                    className="task-complete__info-wrap"
                                                                    style={{ width: '96%', margin: '12px' }}
                                                                >
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            Comment
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__value">
                                                                            {item.comment}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                            if (
                                elem.status === 'need_resend_tr' &&
                                !elem.approved &&
                                !elem.declined &&
                                elem.files.sent_suppliers.length
                            ) {
                                return (
                                    <div
                                        key={elem.id}
                                        className={`tasks__task-complete task-complete green
                              `}
                                    >
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={СompanyВocumentationIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>

                                        {elem.files.suppliers.map((item: any) => {
                                            return (
                                                <div className="task-complete__supplier supplier ">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img
                                                                src={statusImage(
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.company_status_name || ''
                                                                )}
                                                            />{' '}
                                                            {
                                                                data?.suppliers?.find((supp) => supp.id === item.id)
                                                                    ?.company_status_name
                                                            }
                                                            {data?.suppliers?.find((supp) => supp.id === item.id)
                                                                ?.status === 'shortlisted' && (
                                                                <div
                                                                    className="task-complete__title-row"
                                                                    style={{ margin: '0 0 0 15px' }}
                                                                >
                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Awarded
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {data?.suppliers?.find((supp) => supp.id === item.id)
                                                            ?.incumbent && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__row" style={{ position: 'relative' }}>
                                                        <div className="supplier__wrap first with-documents">
                                                            <div className="supplier__title">
                                                                (
                                                                {
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.name_prefix
                                                                }
                                                                ){' '}
                                                                {
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.name
                                                                }
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.trading_name
                                                                }
                                                            </div>
                                                        </div>
                                                        {data?.suppliers?.find((supp) => supp.id === item.id)
                                                            ?.manager_name ? (
                                                            <div className="supplier__wrap with-documents">
                                                                <div className="supplier__title">
                                                                    {data?.suppliers?.find(
                                                                        (supp) => supp.id === item.id
                                                                    )?.manager_name
                                                                        ? data?.suppliers?.find(
                                                                              (supp) => supp.id === item.id
                                                                          )?.manager_name
                                                                        : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {data?.suppliers?.find(
                                                                        (supp) => supp.id === item.id
                                                                    )?.email
                                                                        ? data?.suppliers?.find(
                                                                              (supp) => supp.id === item.id
                                                                          )?.email
                                                                        : ''}{' '}
                                                                    {data?.suppliers?.find(
                                                                        (supp) => supp.id === item.id
                                                                    )?.phone_number
                                                                        ? data?.suppliers?.find(
                                                                              (supp) => supp.id === item.id
                                                                          )?.phone_number
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}
                                                        {item.has_documents ? (
                                                            <></>
                                                        ) : (
                                                            <img
                                                                src={editIcon}
                                                                className="supplier__edit-documents"
                                                                onClick={() => {
                                                                    setTaskId(elem.id);
                                                                    setIsUploadDocumentsModal(true);
                                                                    setSupplierData(item);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    {item.documents.length ? (
                                                        <>
                                                            <div className="supplier__documents">
                                                                <div className="supplier__documents-row">
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                Financial/Management Accounts
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[0]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[0]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                SARS Letter of Good Standing
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[1]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[1]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="supplier__documents-row">
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                B-BBEE Certificate/Affidavit
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[2]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[2]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                Company Registration Certificate
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[3]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[3]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {item?.comment && (
                                                                <div
                                                                    className="task-complete__info-wrap"
                                                                    style={{ width: '96%', margin: '12px' }}
                                                                >
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            Comment
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__value">
                                                                            {item.comment}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                            if (elem.status === 'completed') {
                                if (!elem.files?.suppliers?.[0]?.documents.length) {
                                    return (
                                        <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={СompanyВocumentationIcon} />
                                                    {elem?.name}
                                                </div>
                                                <div className="task-complete__title-row"></div>
                                            </div>
                                            <div className="task-complete__info-wrap">
                                                <>
                                                    <div className="task-complete__wrap">
                                                        <div className="task-complete__name">Received Files</div>
                                                    </div>
                                                    {elem.files.sent_suppliers.map((item: any) => {
                                                        return (
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value">
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center'
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={ReceivedFile}
                                                                            className="upload-download-icon"
                                                                        />
                                                                        {item.name}
                                                                        <div
                                                                            className={`task-complete__download-btn `}
                                                                            onClick={() => {
                                                                                downloadFunc(item.file_uuid, elem.id);
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={downloadIcon}
                                                                                className="upload-download-icon"
                                                                            />
                                                                            Download
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    {elem.comments.tender_representative_sent && (
                                                        <>
                                                            <div className="task-complete__wrap">
                                                                <div className="task-complete__value school-user">
                                                                    <img src={messageGrey} />
                                                                    {elem.comments.tender_representative_sent}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={elem.id} className={`tasks__task-complete task-complete green`}>
                                            <div className="task-complete__title">
                                                <div className="task-complete__title-row">
                                                    <img src={СompanyВocumentationIcon} />
                                                    {elem?.name}
                                                </div>
                                            </div>

                                            {elem.files.suppliers.map((item: any) => {
                                                return (
                                                    <div className="task-complete__supplier supplier ">
                                                        <div className="supplier__header with-switcher">
                                                            <div className="supplier__wrapper">
                                                                <img
                                                                    src={statusImage(
                                                                        data?.suppliers?.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.company_status_name || ''
                                                                    )}
                                                                />{' '}
                                                                {
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.company_status_name
                                                                }
                                                                {data?.suppliers?.find((supp) => supp.id === item.id)
                                                                    ?.status === 'shortlisted' && (
                                                                    <div
                                                                        className="task-complete__title-row"
                                                                        style={{ margin: '0 0 0 15px' }}
                                                                    >
                                                                        <div className="task-complete__approved-active">
                                                                            <img src={done} />
                                                                            Awarded
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            {data?.suppliers?.find((supp) => supp.id === item.id)
                                                                ?.incumbent && (
                                                                <div className="supplier__incumbent">
                                                                    <img src={attantion} />
                                                                    Incumbent Supplier
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="supplier__row" style={{ position: 'relative' }}>
                                                            <div className="supplier__wrap first with-documents">
                                                                <div className="supplier__title">
                                                                    (
                                                                    {
                                                                        data?.suppliers?.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.name_prefix
                                                                    }
                                                                    ){' '}
                                                                    {
                                                                        data?.suppliers?.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.name
                                                                    }
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {
                                                                        data?.suppliers?.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.trading_name
                                                                    }
                                                                </div>
                                                            </div>
                                                            {data?.suppliers?.find((supp) => supp.id === item.id)
                                                                ?.manager_name ? (
                                                                <div className="supplier__wrap with-documents">
                                                                    <div className="supplier__title">
                                                                        {data?.suppliers?.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.manager_name
                                                                            ? data?.suppliers?.find(
                                                                                  (supp) => supp.id === item.id
                                                                              )?.manager_name
                                                                            : ''}
                                                                    </div>
                                                                    <div className="supplier__subtitle">
                                                                        {data?.suppliers?.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.email
                                                                            ? data?.suppliers?.find(
                                                                                  (supp) => supp.id === item.id
                                                                              )?.email
                                                                            : ''}{' '}
                                                                        {data?.suppliers?.find(
                                                                            (supp) => supp.id === item.id
                                                                        )?.phone_number
                                                                            ? data?.suppliers?.find(
                                                                                  (supp) => supp.id === item.id
                                                                              )?.phone_number
                                                                            : ''}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="supplier__wrap"></div>
                                                            )}
                                                        </div>
                                                        {item.documents.length ? (
                                                            <>
                                                                <div className="supplier__documents">
                                                                    <div className="supplier__documents-row">
                                                                        <div className="supplier__documents-item">
                                                                            <div>
                                                                                <div className="supplier__documents-name">
                                                                                    Financial/Management Accounts
                                                                                </div>
                                                                                <div className="supplier__documents-value">
                                                                                    <img src={ReceivedFile} />{' '}
                                                                                    {item.documents?.[0]?.file_name}
                                                                                </div>
                                                                            </div>
                                                                            <img
                                                                                src={Visit}
                                                                                className="supplier__documents-image"
                                                                                onClick={() =>
                                                                                    openFile(
                                                                                        item.documents?.[0]?.file_uuid
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="supplier__documents-item">
                                                                            <div>
                                                                                <div className="supplier__documents-name">
                                                                                    SARS Letter of Good Standing
                                                                                </div>
                                                                                <div className="supplier__documents-value">
                                                                                    <img src={ReceivedFile} />{' '}
                                                                                    {item.documents?.[1]?.file_name}
                                                                                </div>
                                                                            </div>
                                                                            <img
                                                                                src={Visit}
                                                                                className="supplier__documents-image"
                                                                                onClick={() =>
                                                                                    openFile(
                                                                                        item.documents?.[1]?.file_uuid
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="supplier__documents-row">
                                                                        <div className="supplier__documents-item">
                                                                            <div>
                                                                                <div className="supplier__documents-name">
                                                                                    B-BBEE Certificate/Affidavit
                                                                                </div>
                                                                                <div className="supplier__documents-value">
                                                                                    <img src={ReceivedFile} />{' '}
                                                                                    {item.documents?.[2]?.file_name}
                                                                                </div>
                                                                            </div>
                                                                            <img
                                                                                src={Visit}
                                                                                className="supplier__documents-image"
                                                                                onClick={() =>
                                                                                    openFile(
                                                                                        item.documents?.[2]?.file_uuid
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="supplier__documents-item">
                                                                            <div>
                                                                                <div className="supplier__documents-name">
                                                                                    Company Registration Certificate
                                                                                </div>
                                                                                <div className="supplier__documents-value">
                                                                                    <img src={ReceivedFile} />{' '}
                                                                                    {item.documents?.[3]?.file_name}
                                                                                </div>
                                                                            </div>
                                                                            <img
                                                                                src={Visit}
                                                                                className="supplier__documents-image"
                                                                                onClick={() =>
                                                                                    openFile(
                                                                                        item.documents?.[3]?.file_uuid
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {item?.comment && (
                                                                    <div
                                                                        className="task-complete__info-wrap"
                                                                        style={{ width: '96%', margin: '12px' }}
                                                                    >
                                                                        <div className="task-complete__wrap">
                                                                            <div className="task-complete__name">
                                                                                Comment
                                                                            </div>
                                                                        </div>
                                                                        <div className="task-complete__wrap">
                                                                            <div className="task-complete__value">
                                                                                {item.comment}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                }
                            }
                        }
                        if (role === 'school_user') {
                            if (
                                (elem.status === 'pending_su' ||
                                    elem.status === 'completed' ||
                                    elem.status === 'need_resend_tr') &&
                                !elem.approved &&
                                !elem.declined &&
                                !elem.files?.suppliers?.[0]?.documents
                            ) {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={СompanyВocumentationIcon} />
                                                {elem?.name}
                                            </div>
                                            <div className="task-complete__title-row"></div>
                                        </div>
                                        <div className="task-complete__info-wrap">
                                            <>
                                                <div className="task-complete__wrap">
                                                    <div className="task-complete__name">Received Files</div>
                                                </div>
                                                {elem.files.sent_suppliers.map((item: any) => {
                                                    return (
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <img
                                                                        src={ReceivedFile}
                                                                        className="upload-download-icon"
                                                                    />
                                                                    {item.name}
                                                                    <div
                                                                        className={`task-complete__download-btn `}
                                                                        onClick={() => {
                                                                            downloadFunc(item.file_uuid, elem.id);
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={downloadIcon}
                                                                            className="upload-download-icon"
                                                                        />
                                                                        Download
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                {elem.comments.tender_representative_sent && (
                                                    <>
                                                        <div className="task-complete__wrap">
                                                            <div className="task-complete__value school-user">
                                                                <img src={messageGrey} />
                                                                {elem.comments.tender_representative_sent}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={elem.id} className={`tasks__task-complete task-complete`}>
                                        <div className="task-complete__title">
                                            <div className="task-complete__title-row">
                                                <img src={СompanyВocumentationIcon} />
                                                {elem?.name}
                                            </div>
                                        </div>

                                        {elem.files.suppliers.map((item: any) => {
                                            return (
                                                <div className="task-complete__supplier supplier ">
                                                    <div className="supplier__header with-switcher">
                                                        <div className="supplier__wrapper">
                                                            <img
                                                                src={statusImage(
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.company_status_name || ''
                                                                )}
                                                            />{' '}
                                                            {
                                                                data?.suppliers?.find((supp) => supp.id === item.id)
                                                                    ?.company_status_name
                                                            }
                                                            {data?.suppliers?.find((supp) => supp.id === item.id)
                                                                ?.status === 'shortlisted' && (
                                                                <div
                                                                    className="task-complete__title-row"
                                                                    style={{ margin: '0 0 0 15px' }}
                                                                >
                                                                    <div className="task-complete__approved-active">
                                                                        <img src={done} />
                                                                        Awarded
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                        {data?.suppliers?.find((supp) => supp.id === item.id)
                                                            ?.incumbent && (
                                                            <div className="supplier__incumbent">
                                                                <img src={attantion} />
                                                                Incumbent Supplier
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="supplier__row" style={{ position: 'relative' }}>
                                                        <div className="supplier__wrap first with-documents">
                                                            <div className="supplier__title">
                                                                (
                                                                {
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.name_prefix
                                                                }
                                                                ){' '}
                                                                {
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.name
                                                                }
                                                            </div>
                                                            <div className="supplier__subtitle">
                                                                {
                                                                    data?.suppliers?.find((supp) => supp.id === item.id)
                                                                        ?.trading_name
                                                                }
                                                            </div>
                                                        </div>
                                                        {data?.suppliers?.find((supp) => supp.id === item.id)
                                                            ?.manager_name ? (
                                                            <div className="supplier__wrap with-documents">
                                                                <div className="supplier__title">
                                                                    {data?.suppliers?.find(
                                                                        (supp) => supp.id === item.id
                                                                    )?.manager_name
                                                                        ? data?.suppliers?.find(
                                                                              (supp) => supp.id === item.id
                                                                          )?.manager_name
                                                                        : ''}
                                                                </div>
                                                                <div className="supplier__subtitle">
                                                                    {data?.suppliers?.find(
                                                                        (supp) => supp.id === item.id
                                                                    )?.email
                                                                        ? data?.suppliers?.find(
                                                                              (supp) => supp.id === item.id
                                                                          )?.email
                                                                        : ''}{' '}
                                                                    {data?.suppliers?.find(
                                                                        (supp) => supp.id === item.id
                                                                    )?.phone_number
                                                                        ? data?.suppliers?.find(
                                                                              (supp) => supp.id === item.id
                                                                          )?.phone_number
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="supplier__wrap"></div>
                                                        )}
                                                    </div>
                                                    {item.documents.length ? (
                                                        <>
                                                            <div className="supplier__documents">
                                                                <div className="supplier__documents-row">
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                Financial/Management Accounts
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[0]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[0]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                SARS Letter of Good Standing
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[1]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[1]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="supplier__documents-row">
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                B-BBEE Certificate/Affidavit
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[2]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[2]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="supplier__documents-item">
                                                                        <div>
                                                                            <div className="supplier__documents-name">
                                                                                Company Registration Certificate
                                                                            </div>
                                                                            <div className="supplier__documents-value">
                                                                                <img src={ReceivedFile} />{' '}
                                                                                {item.documents?.[3]?.file_name}
                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={Visit}
                                                                            className="supplier__documents-image"
                                                                            onClick={() =>
                                                                                openFile(item.documents?.[3]?.file_uuid)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {item?.comment && (
                                                                <div
                                                                    className="task-complete__info-wrap"
                                                                    style={{ width: '96%', margin: '12px' }}
                                                                >
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__name">
                                                                            Comment
                                                                        </div>
                                                                    </div>
                                                                    <div className="task-complete__wrap">
                                                                        <div className="task-complete__value">
                                                                            {item.comment}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                        }
                    }
                })
            ) : (
                <></>
            )}

            {isModal && (
                <SuppliersFilesModal
                    openModal={isModal}
                    setOpenModal={setIsModal}
                    data={data}
                    taskId={taskId}
                    messageApi={messageApi}
                    setTrigger={setTrigger}
                    name={'Company Documentation'}
                    specialFormats={['zip']}
                />
            )}
            {isDeclineModal && (
                <RejectNegotiatedSupplyAgreement
                    openModal={isDeclineModal}
                    setOpenModal={setIsDeclineModal}
                    setTrigger={setTrigger}
                    data={data}
                    taskId={taskId}
                />
            )}
            {isUploadDocumentsModal && (
                <UploadCompanyDocumentationModal
                    openModal={isUploadDocumentsModal}
                    setOpenModal={setIsUploadDocumentsModal}
                    setTrigger={setTrigger}
                    data={data}
                    taskId={taskId}
                    supplierData={supplierData}
                    messageApi={messageApi}
                />
            )}
            {isDeleteFileModal && (
                <Modal
                    className="confirmModal"
                    centered
                    open={isDeleteFileModal}
                    width={660}
                    closeIcon={<></>}
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyItems: 'space-between',
                                gap: '16px',
                                width: '100%',
                                margin: '25px 0 0 0'
                            }}
                        >
                            <Button key="back" onClick={() => setIsDeleteFileModal(false)} block className="cancel">
                                Cancel
                            </Button>
                            <button className="quit" style={{ width: '100%' }} onClick={deleteFileFunc}>
                                {isLoadingResetAction ? <Loader /> : 'Delete'}
                            </button>
                        </div>
                    }
                >
                    <div className="modal-confirm">
                        <div className="modal-confirm__subtitle" style={{ color: '#4D5E93' }}>
                            Are you sure you want to delete the file?
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};
